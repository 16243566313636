// packages
import { z } from "zod";
import { useFormContext } from "react-hook-form";

// components
import { PetInfoEditor } from "@/shared/components/PetInfoEditor";

// hooks
import { useBreeds } from "@/shared/hooks/useBreeds";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

// types
import { Quote } from "@/shared/types/Quote.interface";
import { UsPolicyStepSchema } from "@/quote-ptz-us/src/schema/PtzUsQuote";
import { PetInfoConfigProps } from "@/shared/components/PetInfoEditor";
import { PetBreedSchema } from "@/quote-fallback-us/src/hooks/useBreedData";

type AllPolicyStepProps = z.infer<typeof UsPolicyStepSchema>;
type StepKeys = Extract<keyof Quote, keyof AllPolicyStepProps>;

interface PetInfoWrapperProps {
    firstPetOnly?: boolean;
    breedData?: PetBreedSchema[]; // hardcoded breed data
    petInfoConfigProps: Omit<PetInfoConfigProps, "petBreeds" | "policyIndex" | "allowDelete">;
}

export function PetInfoWrapper(props: PetInfoWrapperProps) {
    const { firstPetOnly, petInfoConfigProps, breedData } = props;
    const { appState } = useAppLayerContext();
    const { underwriter } = appState;
    const shouldFetchBreeds = !breedData?.length;
    const { breeds } = useBreeds(underwriter, shouldFetchBreeds);
    const petBreeds = shouldFetchBreeds ? breeds : breedData;

    const { watch } = useFormContext<AllPolicyStepProps>();
    const policies = watch("policies");
    const policiesToShow = firstPetOnly && policies?.length > 1 ? policies.slice(0, 1) : policies;

    return (
        <div className="flex flex-1 flex-col gap-6 bg-background-primary">
            {policiesToShow.map((field, index) => {
                return (
                    <div key={field?.id ?? `field-${index}`} data-testid="pet-row">
                        <PetInfoEditor petBreeds={petBreeds} policyIndex={index} allowDelete={policiesToShow?.length > 1} {...petInfoConfigProps} />
                    </div>
                );
            })}
        </div>
    );
}
