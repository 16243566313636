import Blockquote from "@/shared/components/ui/blockquote";

type calloutBlockquote = {
    topText: string;
    bottomText: string | JSX.Element;
};

interface PolicyCalloutProps {
    title?: string | JSX.Element;
    subTitle?: string | JSX.Element;
    description?: string | JSX.Element;
    blockquotes?: calloutBlockquote[];
}

export default function PolicyCallout(props: PolicyCalloutProps) {
    // Default data:
    const defaultTitle = `America's Favorite Pet Insurance`;
    const defaultSubTitle = `Worried about unexpected vet bills? We’ve got you covered.`;
    const defaultDescription = (
        <>
            Every 6 seconds, a pet owner is faced with a vet bill of $1,000 or more.<sup className="text-xs">1</sup> Spot plans can reimburse up to 90% of covered vet bills.
        </>
    );
    const defaultBlockquotes = [
        {
            topText: "$1,000+",
            bottomText: (
                <>
                    of discounts from special perks<sup className="text-xs">2</sup>
                </>
            )
        },
        {
            topText: "30-day",
            bottomText: (
                <>
                    money back guarantee<sup className="text-xs">3</sup>
                </>
            )
        },
        {
            topText: "$164M",
            bottomText: (
                <>
                    in claims paid out<sup className="text-xs">4</sup>
                </>
            )
        }
    ];
    // Props:
    const { title = defaultTitle, subTitle = defaultSubTitle, description = defaultDescription, blockquotes = defaultBlockquotes } = props;
    return (
        <div className="flex flex-col gap-3 sm:gap-5 md:gap-10">
            <div>
                <h1 className="text-base font-bold text-content-brand-b md:text-lg">{title}</h1>
                <h2 className="h1 my-1 text-2xl leading-8 sm:my-2 sm:text-3xl sm:leading-9 md:mb-4 md:mt-1 md:text-5xl md:font-extrabold md:leading-[56px]">{subTitle}</h2>
                <p className="text-sm font-medium text-content-primary sm:text-base md:text-xl">{description}</p>
            </div>
            <div className="flex flex-col gap-3 sm:gap-5 md:gap-6">
                {blockquotes.map((quote, index) => (
                    <Blockquote key={index} topText={quote.topText} bottomText={quote.bottomText} />
                ))}
            </div>
        </div>
    );
}
