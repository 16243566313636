// PolicyErrorController.tsx
import { useEffect, useMemo } from "react";
import { useFormContext, Path } from "react-hook-form";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { ErrorIdType } from "../types/SpotAPI";
import Strings from "../utils/Strings.constants";
import { Quote } from "../types/Quote.interface";

type QuotePath = Path<Quote>;

type UserContactErrorType = Extract<ErrorIdType, "invalid-postal-code" | "location-not-available" | "invalid-phone-number" | "account-exists" | "priority-code-deprecated">;

type PetErrorType = Extract<ErrorIdType, "invalid-pet-name" | "invalid-pet-birthday" | "invalid-microchip">;

interface PolicyErrorControllerProps {
    errors: {
        "invalid-postal-code": string;
    };
}

export function PolicyErrorController(props: PolicyErrorControllerProps) {
    const {
        setError,
        setFocus,
        clearErrors,
        formState: { submitCount }
    } = useFormContext<Quote>();

    const {
        appState: { asyncErrors, isEmailSuggestionShowing, underwriter },
        updateAppState
    } = useAppLayerContext();

    const userContactErrorMappings: Record<UserContactErrorType, { path: QuotePath; message: string }> = useMemo(
        () => ({
            "invalid-postal-code": { path: "ratingZipcode", message: props.errors["invalid-postal-code"] },
            "location-not-available": { path: "ratingZipcode", message: Strings.ERRORS.LOCATION_UNAVAILABLE },
            "invalid-phone-number": { path: "phone", message: "Invalid phone number" },
            "account-exists": { path: "email", message: "This email is already registered" },
            "priority-code-deprecated": { path: "" as QuotePath, message: "" } // pcode error handled via retry event
        }),
        [props.errors]
    );

    const petErrorMappings: Record<PetErrorType, (errorPath: string) => { path: QuotePath; message: string } | undefined> = useMemo(
        () => ({
            "invalid-pet-name": errorPath => {
                const match = errorPath.match(/pets\[(\d+)\]/);
                if (!match) return undefined;
                return {
                    path: `policies.${match[1]}.name` as QuotePath,
                    message: Strings.ERRORS.PET_NAME_INVALID
                };
            },
            "invalid-pet-birthday": errorPath => {
                const match = errorPath.match(/pets\[(\d+)\]/);
                if (!match) return undefined;
                return {
                    path: (underwriter === "ptz-ca" ? `policies.${match[1]}.birthYear` : `policies.${match[1]}.age`) as QuotePath,
                    message: Strings.ERRORS.PET_AGE_INVALID
                };
            },
            "invalid-microchip": errorPath => {
                const match = errorPath.match(/pets\[(\d+)\]/);
                if (!match) return undefined;
                return {
                    path: `policies.${match[1]}.extra.microchipID` as QuotePath,
                    message: Strings.ERRORS.MICROCHIP_INVALID_ASYNC
                };
            }
        }),
        [underwriter]
    );

    useEffect(() => {
        if (!asyncErrors?.length || submitCount === 0) return;

        clearErrors();

        if (!!isEmailSuggestionShowing) {
            setFocus("email");
            return;
        }

        let handledAllErrors = true;
        let firstErrorPath: QuotePath | undefined;

        asyncErrors.forEach(error => {
            // Handle pet errors (with path transformation)
            if (error.id in petErrorMappings && error.at) {
                const errorMapping = petErrorMappings[error.id as PetErrorType](error.at);
                if (errorMapping) {
                    setTimeout(() => {
                        setError(errorMapping.path, {
                            type: "manual",
                            message: error.message ?? errorMapping.message
                        });
                        if (!firstErrorPath) {
                            firstErrorPath = errorMapping.path;
                            setFocus(errorMapping.path);
                        }
                    }, 100);
                } else {
                    handledAllErrors = false;
                }
            }
            // Handle user contact errors (static paths)
            else if (error.id in userContactErrorMappings) {
                const errorMapping = userContactErrorMappings[error.id as UserContactErrorType];
                if (errorMapping?.path) {
                    setError(errorMapping.path, {
                        type: "manual",
                        message: errorMapping.message
                    });
                    if (!firstErrorPath) {
                        firstErrorPath = errorMapping.path;
                    }
                }
            } else {
                handledAllErrors = false;
            }
        });

        // Set focus to the first error encountered
        if (firstErrorPath) {
            setFocus(firstErrorPath);
        }

        if (!handledAllErrors) {
            updateAppState({ hasUnknownError: true });
        }
    }, [asyncErrors, clearErrors, isEmailSuggestionShowing, setError, setFocus, submitCount, updateAppState, userContactErrorMappings, petErrorMappings]);

    return null;
}
